import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import { saveShippingAddress } from "../Redux/Actions/cartActions";
import { POSTAL_CODES } from "../Redux/Constants/GlobalConstant";
import Select from "react-select";
import MobileNav from "../components/MobileNav";

const options = POSTAL_CODES;

const ShippingScreen = ({ history }) => {
  // window.scrollTo(0, 0);

  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const [address, setAddress] = useState(shippingAddress.address);
  const [city, setCity] = useState(shippingAddress.city);
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
  const [country, setCountry] = useState(
    shippingAddress.country ? shippingAddress.country : "UP-India"
  );
  const [selectedOption, setSelectedOption] = useState({
    label: "Enter / Select Pincode",
    value: null,
  });

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    if (postalCode) {
      dispatch(saveShippingAddress({ address, city, postalCode, country }));
      history.push("/payment");
    } else {
      alert("Enter Pincode");
    }
  };

  const handleChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedOption(selectedOption);
    setPostalCode(selectedOption.label);
    console.log(postalCode);
    // this.setState({ selectedOption }, () =>
    //   console.log(`Option selected:`, this.state.selectedOption)
    // );
  };
  useEffect(() => {
    if (shippingAddress.postalCode)
      setSelectedOption({
        label: shippingAddress.postalCode,
        value: null,
      });
  }, [shippingAddress.postalCode]);

  return (
    <>
      <Header />
      <MobileNav/>
      <div className="container d-flex justify-content-center align-items-center login-center">
        <form
          className="Login col-md-8 col-lg-4 col-11"
          onSubmit={submitHandler}
        >
          <h6>DELIVERY ADDRESS</h6>
          <input
            type="text"
            placeholder="Enter Full-Address"
            value={address}
            required
            onChange={(e) => setAddress(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter Town-City"
            value={city}
            required
            onChange={(e) => setCity(e.target.value)}
          />  

          <Select
            className="shipping-pincode-select"
            value={selectedOption}
            onChange={(e) => handleChange(e)}
            options={options}
            defaultValue={""}
            required
          />

          {/* <select
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            defaultValue={""}
          >
            <option value={""}>Select Postal Code</option>
            {
            Object.keys(POSTAL_CODES).map(function(keyName, keyIndex) {
              // use keyName to get current key's name
              // and a[keyName] to get its value
              return (<option value={""}>{POSTAL_CODES[keyName]}</option>);
            })
           }
          </select> */}

          <input
            type="text"
            placeholder="Enter State/Country"
            value={country}
            required
            onChange={(e) => setCountry(e.target.value)}
          />
          <button type="submit">Continue</button>
        </form>
      </div>
    </>
  );
};

export default ShippingScreen;
