import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listProductsGroupWise } from "../../Redux/Actions/ProductActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import { IMAGE_CACHE_URL } from "../../Redux/Constants/GlobalConstant";
import QuantityInput from "../QuantityInput";

const CustomThumbSection = (props) => {
  const dispatch = useDispatch();
  const { groupId } = props;
  const [products, setProducts] = useState(null);
  const [heading, setHeading] = useState(null);

  // const productList = useSelector((state) => state.productList);

  const groupListDetail = useSelector((state) => state.groupProductList);
  const { loading, error, groupProducts } = groupListDetail;

  useEffect(() => {
    if (!groupProducts) dispatch(listProductsGroupWise(groupId));
    if (groupProducts && !products) {
      // console.log("Group List", groupProducts);
      setProducts(groupProducts.products);
      setHeading(groupProducts?.group_name);
    }
  }, [dispatch, groupId, groupProducts, products]);

  return (
    <>
      <div className="container">
        <div className="section">
          <div className="row mobile-thumbnail">
            <div className="col-lg-12 col-md-12 article">
              <h5>{heading}</h5>
              <div className="best-deal-shopcontainer row">
                {loading ? (
                  <div className="mb-5">
                    <Loading />
                  </div>
                ) : error ? (
                  <Message variant="alert-danger">{error}</Message>
                ) : (
                  <>
                    {products?.map((product, i) => (
                      <div className="shop col-lg-2 col-md-3 col-sm-3" key={i}>
                        <div className="border-product">
                          <Link to={`/products/${product._id}`}>
                            <div className="shopBack">
                              {product.mrp_price - product.price > 0 && (
                                <div className="offer_strip">
                                  {Math.floor(
                                    ((product.mrp_price - product.price) /
                                      product.price) *
                                      100
                                  )}
                                  % off
                                </div>
                              )}
                              <img
                                src={IMAGE_CACHE_URL + product.image}
                                alt={product.name}
                              />
                            </div>
                          </Link>

                          <div className="shoptext">
                            <p>
                              <Link to={`/products/${product._id}`}>
                                {product.name} - {product?.unit}
                              </Link>
                            </p>
                            <div className="price-button-thumb-sec">
                              <h3>
                                {product.mrp_price - product.price > 0 && (
                                  <span className="text-overline">
                                    ₹ {product.mrp_price}
                                  </span>
                                )}
                                <span>₹ {product.price}</span>
                                {/* {product.mrp_price - product.price < 0 && (
                                      <span className="text-overline"> </span>
                                    )} */}
                              </h3>
                              <QuantityInput
                                productId={product._id}
                                renderAddButton={true}
                                qty={0}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomThumbSection;
