export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const BEST_DEAL_PRODUCT_LIST_REQUEST = "BEST_DEAL_PRODUCT_LIST_REQUEST";
export const BEST_DEAL_PRODUCT_LIST_SUCCESS = "BEST_DEAL_PRODUCT_LIST_SUCCESS";
export const BEST_DEAL_PRODUCT_LIST_FAIL = "BEST_DEAL_PRODUCT_LIST_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const PRODUCT_CREATE_REVIEW_REQUEST = "PRODUCT_CREATE_REVIEW_REQUEST";
export const PRODUCT_CREATE_REVIEW_SUCCESS = "PRODUCT_CREATE_REVIEW_SUCCESS";
export const PRODUCT_CREATE_REVIEW_FAIL = "PRODUCT_CREATE_REVIEW_FAIL";
export const PRODUCT_CREATE_REVIEW_RESET = "PRODUCT_CREATE_REVIEW_RESET";

export const GROUP_LIST_REQUEST = "GROUP_LIST_REQUEST";
export const GROUP_LIST_SUCCESS = "GROUP_LIST_SUCCESS";
export const GROUP_LIST_FAIL = "GROUP_LIST_FAIL";

export const CAT_PRODUCT_LIST = "CAT_PRODUCT_LIST";

export const CAT_LIST_REQUEST = "CAT_LIST_REQUEST";
export const CAT_LIST_SUCCESS = "CAT_LIST_SUCCESS";
export const CAT_LIST_FAIL = "CAT_LIST_FAIL";


export const GROUP_PRODUCT_REQUEST = "GROUP_PRODUCT_REQUEST";
export const GROUP_PRODUCT_SUCCESS = "GROUP_PRODUCT_SUCCESS";
export const GROUP_PRODUCT_FAIL = "GROUP_PRODUCT_FAIL";

export const GROUP_CATEGORY_REQUEST = "GROUP_CATEGORY_REQUEST";
export const GROUP_CATEGORY_SUCCESS = "GROUP_CATEGORY_SUCCESS";
export const GROUP_CATEGORY_FAIL = "GROUP_CATEGORY_FAIL";
