import React from "react";
import Header from "../components/Header";
import MobileNav from "../components/MobileNav";

const TermConditionScreen = () => {
  return (
    <>
      <Header />
      <MobileNav />
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        <h1>Terms and Conditions</h1>
        <p>Last updated on June 10, 2024</p>

        <div class="section term-condition-section">
          <h2>Terms of Use</h2>
          <p>
            “Agrocroft” is a trademark of “GC Groups” (formerly known as GC
            Groups), a company incorporated under the Companies Act, 2013 with
            its registered office at Ward No 7, Nichlaul Maharajganj, UP.
            273304. The domain name www.agrocroft.com is owned by the Company.
            The Company holds an FSSAI license, bearing no. ________________.
          </p>
        </div>

        <div class="section term-condition-section">
          <h2>Acceptance of Terms</h2>
          <p>
            Thank you for choosing agrocroft. These Terms of Use (the "Terms")
            are designed to inform you of your legal rights and responsibilities
            concerning your access to and use of the agrocroft website at
            www.agrocroft.com (the "Site") and any related mobile or software
            applications ("agrocroft Platform"), including but not limited to
            delivering information via the website now or in the future that
            link to these Terms (collectively, the "Services").
          </p>

          <p>
            These Terms apply to all existing and future agrocroft customers. By
            accessing this site (hereinafter the “Marketplace”), you agree to be
            bound by these Terms, acknowledging that it forms a binding
            agreement between you and the Company (hereinafter the “User
            Agreement”). You may not use the Services if you do not accept the
            Terms or are unable to be bound by them. Your use of the agrocroft
            Platform is at your own risk, including the risk that you might be
            exposed to content that is objectionable or otherwise inappropriate.
          </p>

          <p>
            This document is published in accordance with the provisions of Rule
            3 of the Information Technology (Intermediaries Guidelines) Rules,
            2011. The User Agreement may be updated from time to time by the
            Company without notice. It is therefore strongly recommended that
            you review the User Agreement, as available on the Marketplace, each
            time you access and/or use the Marketplace.
          </p>

          <p>
            The terms ‘visitor(s)’, ‘user(s)’, ‘you’ refer to individuals
            visiting, accessing, browsing through, and/or using the Marketplace
            at any time.
          </p>

          <p>
            If you have any clarifications regarding the Terms of Use, please
            write to us at info@agrocroft.com
          </p>
        </div>
        <div class="section term-condition-section">
          {/* <h2>Introduction</h2>
          <p>
            Welcome to Agrocroft.com. By accessing and using our website, you
            agree to comply with and be bound by the following terms and
            conditions. Please review them carefully.
          </p> */}
          {/* <h2> Company Information</h2>
          <p>
            Agrocroft.com is operated by GC Groups, located at Nichlaul,
            Maharajganj. For any questions or concerns, you can contact us at
            [9517290551].
          </p> */}
          <h2> Services Provided</h2>
          <p>
            Agrocroft.com provides an online platform for purchasing groceries
            and related products. We offer a wide range of products, including
            fresh produce, packaged goods, and household essentials.
          </p>
          <h2>User Accounts</h2>
          <p>
            To use certain features of our website, you may be required to
            create an account. You are responsible for maintaining the
            confidentiality of your account information and for all activities
            that occur under your account. You agree to provide accurate and
            complete information during the registration process and to update
            such information as necessary.
          </p>

          <h2>Ordering Process</h2>
          <p>
            Orders can be placed through our website by selecting the desired
            products and completing the checkout process. By placing an order,
            you agree to purchase the products in accordance with these terms
            and conditions. All orders are subject to availability and
            acceptance by Agrocroft.com.
          </p>
          <h2>Payment Terms</h2>
          <p>
            We accept various payment methods, including credit cards, debit
            cards, and online payment services. Payment must be made at the time
            of purchase. By providing your payment information, you represent
            and warrant that you are authorized to use the designated payment
            method.
          </p>
          <h2> Delivery Policy </h2>
          <p>
            We offer delivery services to specified locations. Delivery times
            and costs may vary based on the location and the size of the order.
            Estimated delivery times will be provided at the time of order
            placement. We are not responsible for delays caused by external
            factors beyond our control.
          </p>
          <h2>Return and Refund Policy </h2>
          <p>
            We strive to ensure the quality of our products. If you are not
            satisfied with your purchase, you may return the products in
            accordance with our return policy. Please contact our customer
            service within [6-7] days of receiving your order to initiate a
            return. Refunds will be processed based on the original payment
            method.
          </p>
          <h2>Privacy Policy </h2>
          <p>
            We are committed to protecting your privacy. Our Privacy Policy,
            which is incorporated into these terms and conditions, explains how
            we collect, use, and protect your personal information. By using our
            website, you agree to the terms of our Privacy Policy.
          </p>
          <h2>User Responsibilities </h2>
          <p>
            You agree to use our website for lawful purposes only. You are
            prohibited from posting or transmitting any unlawful, harmful,
            threatening, abusive, defamatory, obscene, or otherwise
            objectionable content. You also agree not to interfere with the
            proper functioning of our website or engage in any activity that
            could damage, disable, or impair our servers or networks.
          </p>
          <h2>Limitation of Liability </h2>
          <p>
            Agrocroft.com and its affiliates shall not be liable for any direct,
            indirect, incidental, special, or consequential damages arising from
            your use of our website or the purchase of our products. This
            includes, but is not limited to, damages for loss of profits, data,
            or other intangible losses.
          </p>
          <h2>Dispute Resolution </h2>
          <p>
            Any disputes arising out of or related to these terms and conditions
            or your use of our website shall be resolved through binding
            arbitration in accordance with the rules of [Arbitration
            Association]. The arbitration shall be conducted in [Jurisdiction],
            and the award rendered by the arbitrator may be entered in any court
            having jurisdiction thereof.
          </p>
          <h2>Modifications </h2>
          <p>
            We reserve the right to modify these terms and conditions at any
            time. Any changes will be effective immediately upon posting on our
            website. Your continued use of our website after any modifications
            constitutes your acceptance of the revised terms and conditions.
          </p>
          <h2>Contact Information for Issues </h2>
          <p>
            If you have any questions or concerns regarding these terms and
            conditions, please contact us at [91-9517290551].
          </p>
        </div>
        <div class="section term-condition-section">
          <h2>Services Overview</h2>
          <p>
            The Marketplace is a platform for domestic consumers to transact
            with third-party sellers, who have been granted access to the
            Marketplace to display and offer products for sale. For clarity, the
            Company does not provide any services to users other than providing
            the Marketplace as a platform to transact at their own cost and
            risk, and other services as may be specifically notified in writing.
          </p>

          <p>
            The Company is not and cannot be a party to any transaction between
            you and the third-party sellers, nor does it have any control,
            involvement, or influence over the products purchased by you from
            such sellers or the prices charged by them. The Company therefore
            disclaims all warranties and liabilities associated with any
            products offered on the Marketplace.
          </p>

          <p>
            Services on the Marketplace are available only to select geographies
            in India and are subject to restrictions based on business hours and
            days of third-party sellers. Transactions through the Marketplace
            may be subject to a delivery charge where the minimum order value is
            not met. You will be informed of such charges at the stage of
            check-out for a transaction.
          </p>

          <p>
            Transactions through the Marketplace may also be subject to a
            convenience & safety fee, which will be communicated at the
            check-out stage.
          </p>
        </div>

        <div class="section term-condition-section">
          <h2>Eligibility</h2>
          <p>
            To use certain features of our website, you may be required to
            create an account. You are responsible for maintaining the
            confidentiality of your account information and for all activities
            that occur under your account. You agree to provide accurate and
            complete information during the registration process and to update
            such information as necessary.
          </p>
          <p>
            Individuals who are “incompetent to contract” within the meaning of
            the Indian Contract Act, 1872, including minors, undischarged
            insolvents, etc., are not eligible to use/access the Marketplace.
            However, if you are a minor, i.e., under the age of 18 years, you
            may use/access the Marketplace under the supervision of an adult
            parent or legal guardian who agrees to be bound by these Terms of
            Use. You are prohibited (even under provision) from purchasing any
            product(s) meant for adult consumption, the sale of which to minors
            is forbidden.
          </p>

          <p>
            The Marketplace is intended for end-consumers desirous of purchasing
            products for domestic self-consumption. Retailers, institutions,
            wholesalers, or any other business users are not eligible to use the
            Marketplace to purchase products from third-party sellers.
          </p>

          <p>
            The Company, at its sole discretion and without liability, reserves
            the right to terminate or refuse your registration or deny access to
            the Marketplace if: (i) it is discovered or brought to notice that
            you do not conform to the eligibility criteria, or (ii) the Company
            has reason to believe (including through evaluating usage patterns)
            that the eligibility criteria are not met or violated, or (iii) your
            usage may breach the terms of this User Agreement. To determine
            compliance with eligibility criteria, the Company uses an algorithm
            and/or pre-determined criteria-based technology, which may restrict
            or block your usage from time to time. If you are a genuine domestic
            user, please contact us for assistance.
          </p>
        </div>

        <div class="section term-condition-section">
          <h2>Licence & Access</h2>
          <p>
            The Company grants you a limited sub-license to access and make
            personal use of the Marketplace, but not to download (other than
            page caching) or modify it, or any portion of it, except with
            express prior written consent of the Company. This limited
            sublicense does not include/permit any resale or commercial use of
            the Marketplace or its contents; any collection and use of any
            product listings, descriptions, or prices; any derivative use of the
            Marketplace or its contents; any downloading or copying of
            information for the benefit of another third party; or any use of
            data mining, robots, or similar data gathering and extraction tools.
          </p>

          <p>
            The Marketplace or any portion of it may not be reproduced,
            duplicated, copied, sold, resold, visited, or otherwise exploited
            for any commercial purpose without express prior written consent
            from the Company.
          </p>

          <p>
            You may not frame or utilize framing techniques to enclose any
            trademark, logo, or other proprietary information (including images,
            text, page layout, or form) of the Marketplace or the Company and/or
            its affiliates without express prior written consent from the
            Company.
          </p>

          <p>
            You may not use any meta tags or any other “hidden text” utilizing
            the Company’s name or trademarks without express prior written
            consent from the Company.
          </p>

          <p>
            You shall not attempt to gain unauthorized access to any portion or
            feature of the Marketplace, or any other systems or networks
            connected to it, or to any server, computer, network, or to any of
            the services offered on or through the Marketplace, by hacking,
            ‘password mining,’ or any other illegitimate means.
          </p>

          <p>
            You hereby agree and undertake not to host, display, upload, modify,
            publish, transmit, update or share any information which:
            <ul>
              <li>
                Belongs to another person and to which you do not have any
                right;
              </li>
              <li>
                Is grossly harmful, harassing, blasphemous, defamatory, obscene,
                pornographic, paedophilic, libelous, invasive of another’s
                privacy, hateful, or racially, ethnically objectionable,
                disparaging, relating or encouraging money laundering or
                gambling, or otherwise unlawful in any manner;
              </li>
              <li>Harms minors in any way;</li>
              <li>
                Infringes any patent, trademark, copyright or other
                proprietary/intellectual property right;
              </li>
              <li>Violates any law currently in force;</li>
              <li>
                Deceives or misleads the addressee about the origin of such
                messages, communicates any information which is grossly
                offensive or menacing in nature;
              </li>
              <li>
                Contains software viruses or any other computer code, files or
                programs designed to interrupt, destroy or limit the
                functionality of any computer resource;
              </li>
              <li>
                Threatens the unity, integrity, defense, security or sovereignty
                of India, friendly relations with foreign states, or public
                order or causes incitement to the commission of any cognizable
                offense or prevents investigation of any offense or is insulting
                to any other nation;
              </li>
              <li>Is misleading or known to be false in any way.</li>
            </ul>
          </p>

          <p>
            Any unauthorized use shall automatically terminate the permission or
            sub-license granted by the Company.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermConditionScreen;
