const apiUrl = process.env.REACT_APP_API_URL;
// const environment = process.env.REACT_APP_ENVIRONMENT;
const imageCacheUrl = process.env.REACT_APP_IMAGE_CACHE_URL;
const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

export const BASE_URL = apiUrl;
// export const BASE_URL = "http://localhost:4000";
export const IMAGE_CACHE_URL = imageCacheUrl+"/";
export const IMAGE_BASE_URL = imageBaseUrl;

export const RAZORPAY_ID = "rzp_test_Jqnl2f66RJ7gft";
export const RAZORPAY_SECRET = "YOIQyXMb3IbNjAsbfzvXqLTv";

export const TEXTLOCAL_KEY = "Njc0NDQ4NDU2YTQ1NmI0ZDY4N2E3OTQ2NjE1NDU4Mzg=";
export const TEXTLOCAL_SENDER = "ASDOCFLQAS";
export const POSTAL_CODES_OLD = [
  // { label: "273001", value: "Gorakhpur" },
  // { label: "273002", value: "Airforce Area" },
  // { label: "273008", value: "Kunraghat" },
  // { label: "273015", value: "Transport Nagar" },
  // { label: "273006", value: "Shahpur" },
  // { label: "273403", value: "Bansgaon" },
  // { label: "273201", value: "Chauri Chaura" },
  // { label: "273152", value: "Pipraich" },
  // { label: "273209", value: "Gida Gorakhpur" },
  // { label: "273406", value: "Unwal" },
  // { label: "273409", value: "Chargawan" },
  // { label: "273012", value: "Railway Colony" },
  // { label: "273213", value: "Kusumhi" },
  // { label: "273003", value: "Jhungia Bazar" },
  // { label: "273304", value: "Bhojpur" },
  // { label: "273306", value: "Bhathat" },
  // { label: "273404", value: "Belghat" },
  // { label: "273158", value: "Campierganj" },
  // { label: "273005", value: "Gorakhnath Mandir" },
  // { label: "273413", value: "Hainsar Bazar" },
  // { label: "273413", value: "Gopalpur" },
  // { label: "273209", value: "Sahjanwa" },
  // { label: "273165", value: "Pipiganj" },
  // { label: "273010", value: "Khorabar" },
  // { label: "273201", value: "Bhatauli" },
  // { label: "273005", value: "Rajghat" },
  // { label: "273413", value: "Rithuakhor" },
  // { label: "273213", value: "Suba Bazar" },
  // { label: "273407", value: "Uruwa Bazar" },
  // { label: "273413", value: "Kauri Ram" },
  // Maharajganj
  // { label: "273303", value: "Maharajganj" },
  // { label: "273164", value: "Nautanwa" },
  // { label: "273163", value: "Siswa Bazar" },
  // { label: "273155", value: "Anandnagar (Farenda)" },
  // { label: "273151", value: "Ghughli" },
  // { label: "273310", value: "Paniara" },
  // { label: "273301", value: "Partawal" },
  // { label: "273157", value: "Bridgmanganj" },
  // { label: "273162", value: "Kolhui" },
  // { label: "273304", value: "Nichlaul" },
  // { label: "273311", value: "Lalpur" },
  // { label: "273152", value: "Pipraich" },
  // { label: "273302", value: "Shyamdeurwa" },
  // { label: "273309", value: "Dhani Bazar" },
  // { label: "273312", value: "Badhra" },
  // { label: "273306", value: "Sohrauna" },
  // { label: "273153", value: "Mithaura" },
  // { label: "273307", value: "Naugarh" },
  // Deoria
  // { label: "274001", value: "Deoria" },
  // { label: "274701", value: "Bhatni Bazar" },
  // { label: "274601", value: "Barhaj" },
  // { label: "274509", value: "Salempur" },
  // { label: "274502", value: "Lar Road" },
  // { label: "274702", value: "Bhatpar Rani" },
  // { label: "274405", value: "Rampur Karkhana" },
  // { label: "274202", value: "Gauri Bazar" },
  // { label: "274204", value: "Bardagaon" },
  // { label: "274206", value: "Mahen" },
  // { label: "274204", value: "Rudrapur" },
  // { label: "274201", value: "Chaurichaura" },
  // { label: "274404", value: "Pathardeva" },
  // { label: "274602", value: "Bhagalpur" },
  // { label: "274703", value: "Sakrapar" },
  // { label: "274205", value: "Siswa Bazar" },
  // { label: "274406", value: "Chakia" },
  // { label: "274603", value: "Bankata" },
  // { label: "274204", value: "Banakta" },
  // { label: "274404", value: "Deokali" }
];

export const POSTAL_CODES = [
  // { label: "273001 - Gorakhpur", value: "Gorakhpur" },
  // { label: "273002 - Airforce Area", value: "Airforce Area" },
  // { label: "273008 - Kunraghat", value: "Kunraghat" },
  // { label: "273015 - Transport Nagar", value: "Transport Nagar" },
  // { label: "273006 - Shahpur", value: "Shahpur" },
  // { label: "273403 - Bansgaon", value: "Bansgaon" },
  // { label: "273201 - Chauri Chaura", value: "Chauri Chaura" },
  // { label: "273152 - Pipraich", value: "Pipraich" },
  // { label: "273209 - Gida Gorakhpur", value: "Gida Gorakhpur" },
  // { label: "273406 - Unwal", value: "Unwal" },
  // { label: "273409 - Chargawan", value: "Chargawan" },
  // { label: "273012 - Railway Colony", value: "Railway Colony" },
  // { label: "273213 - Kusumhi", value: "Kusumhi" },
  // { label: "273003 - Jhungia Bazar", value: "Jhungia Bazar" },
  // { label: "273304 - Bhojpur", value: "Bhojpur" },
  // { label: "273306 - Bhathat", value: "Bhathat" },
  // { label: "273404 - Belghat", value: "Belghat" },
  // { label: "273158 - Campierganj", value: "Campierganj" },
  // { label: "273005 - Gorakhnath Mandir", value: "Gorakhnath Mandir" },
  // { label: "273413 - Hainsar Bazar", value: "Hainsar Bazar" },
  // { label: "273413 - Gopalpur", value: "Gopalpur" },
  // { label: "273209 - Sahjanwa", value: "Sahjanwa" },
  // { label: "273165 - Pipiganj", value: "Pipiganj" },
  // { label: "273010 - Khorabar", value: "Khorabar" },
  // { label: "273201 - Bhatauli", value: "Bhatauli" },
  // { label: "273005 - Rajghat", value: "Rajghat" },
  // { label: "273413 - Rithuakhor", value: "Rithuakhor" },
  // { label: "273213 - Suba Bazar", value: "Suba Bazar" },
  // { label: "273407 - Uruwa Bazar", value: "Uruwa Bazar" },
  // { label: "273413 - Kauri Ram", value: "Kauri Ram" },

  // Maharajganj
  // { label: "273303 - Maharajganj", value: "Maharajganj" },
  // { label: "273164 - Nautanwa", value: "Nautanwa" },
  // { label: "273163 - Siswa Bazar", value: "Siswa Bazar" },
  // { label: "273155 - Anandnagar (Farenda)", value: "Anandnagar (Farenda)" },
  // { label: "273151 - Ghughli", value: "Ghughli" },
  // { label: "273310 - Paniara", value: "Paniara" },
  // { label: "273301 - Partawal", value: "Partawal" },
  // { label: "273157 - Bridgmanganj", value: "Bridgmanganj" },
  // { label: "273162 - Kolhui", value: "Kolhui" },
  {
    label: "273304 - Ward: 1 Nichlaul",
    value: "Ward: 1 Nichlaul",
    dTime: "25",
  },
  {
    label: "273304 - Ward: 2 Nichlaul",
    value: "Ward: 2 Nichlaul",
    dTime: "25",
  },
  {
    label: "273304 - Ward: 3 Nichlaul",
    value: "Ward: 3 Nichlaul",
    dTime: "25",
  },
  {
    label: "273304 - Ward: 4 Nichlaul",
    value: "Ward: 4 Nichlaul",
    dTime: "25",
  },
  {
    label: "273304 - Ward: 5 Nichlaul",
    value: "Ward: 5 Nichlaul",
    dTime: "25",
  },
  {
    label: "273304 - Ward: 6 Nichlaul",
    value: "Ward: 6 Nichlaul",
    dTime: "25",
  },
  {
    label: "273304 - Ward: 7 Nichlaul",
    value: "Ward: 7 Nichlaul",
    dTime: "25",
  },
  {
    label: "273304 - Ward: 8 Nichlaul",
    value: "Ward: 8 Nichlaul",
    dTime: "25",
  },
  {
    label: "273304 - Ward: 9 Nichlaul",
    value: "Ward: 9 Nichlaul",
    dTime: "25",
  },
  {
    label: "273304 - Ward: 10 Nichlaul",
    value: "Ward: 10 Nichlaul",
    dTime: "25",
  },
  {
    label: "273304 - Ward: 11 Nichlaul",
    value: "Ward: 11 Nichlaul",
    dTime: "25",
  },
  {
    label: "273304 - Ward: 12 Nichlaul",
    value: "Ward: 12 Nichlaul",
    dTime: "25",
  },
  {
    label: "273304 - Ward: 13 Nichlaul",
    value: "Ward: 13 Nichlaul",
    dTime: "25",
  },
  // { label: "273311 - Lalpur", value: "Lalpur" },
  // { label: "273152 - Pipraich", value: "Pipraich" },
  // { label: "273302 - Shyamdeurwa", value: "Shyamdeurwa" },
  // { label: "273309 - Dhani Bazar", value: "Dhani Bazar" },
  // { label: "273312 - Badhra", value: "Badhra" },
  // { label: "273306 - Sohrauna", value: "Sohrauna" },
  // { label: "273153 - Mithaura", value: "Mithaura" },
  // { label: "273307 - Naugarh", value: "Naugarh" },

  // Deoria
  // { label: "274001 - Deoria", value: "Deoria" },
  // { label: "274701 - Bhatni Bazar", value: "Bhatni Bazar" },
  // { label: "274601 - Barhaj", value: "Barhaj" },
  // { label: "274509 - Salempur", value: "Salempur" },
  // { label: "274502 - Lar Road", value: "Lar Road" },
  // { label: "274702 - Bhatpar Rani", value: "Bhatpar Rani" },
  // { label: "274405 - Rampur Karkhana", value: "Rampur Karkhana" },
  // { label: "274202 - Gauri Bazar", value: "Gauri Bazar"},
  // { label: "274204 - Bardagaon", value: "Bardagaon" },
  // { label: "274206 - Mahen", value: "Mahen" },
  // { label: "274204 - Rudrapur", value: "Rudrapur" },
  // { label: "274201 - Chaurichaura", value: "Chaurichaura" },
  // { label: "274404 - Pathardeva", value: "Pathardeva" },
  // { label: "274602 - Bhagalpur", value: "Bhagalpur" },
  // { label: "274703 - Sakrapar", value: "Sakrapar" },
  // { label: "274205 - Siswa Bazar", value: "Siswa Bazar" },
  // { label: "274406 - Chakia", value: "Chakia" },
  // { label: "274603 - Bankata", value: "Bankata" },
  // { label: "274204 - Banakata", value: "Banakta" },
  // { label: "274404 - Keokali", value: "Deokali" }
];
