import { useEffect, useRef } from "react";
import crypto from "crypto";
import { useDispatch } from "react-redux";
import {
  upiPaymentUpdate,
  // getOrderDetails,
} from "../../Redux/Actions/OrderActions";
// import PropTypes from 'prop-types';
// import Axios from 'axios';

// Function to load script and append in DOM tree.
const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      console.log("razorpay loaded successfully");
      resolve(true);
    };
    script.onerror = () => {
      console.log("error in loading razorpay");
      resolve(false);
    };
    document.body.appendChild(script);
  });

const RenderRazorpay = ({
  orderId,
  orderId2,
  keyId,
  keySecret,
  currency,
  amount,
  orderDetail,
}) => {
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);
  const dispatch = useDispatch();
  // const razorOrder = useSelector((state) => state.orderUPI);

  // To load razorpay checkout modal script.
  const displayRazorpay = async (options) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      console.log("Razorpay SDK failed to load. Are you online?");
      return;
    }
    
    // console.log("Going to Launch Model",options);
    // All information is loaded in options which we will discuss later.
    const rzp1 = new window.Razorpay(options);

    // If you want to retreive the chosen payment method.
    rzp1.on("payment.submit", (response) => {
      paymentMethod.current = response.method;
      // console.log("Payment Submit:", paymentMethod.current);
    });

    // To get payment id in case of failed transaction.
    rzp1.on("payment.failed", (response) => {
      paymentId.current = response.error.metadata.payment_id;
      // console.log("payment Id if Failed: ", paymentId.current);
    });

    // to open razorpay checkout modal.
    rzp1.open();
  };

  // informing server about payment
  const handlePayment = async (status, orderDetails = {}) => {
    // console.log(orderId, orderId2, orderDetails);
    dispatch(upiPaymentUpdate(orderId2, orderDetails));
  };

  // we will be filling this object in next step.
  const options = {
    key: keyId, // key id from props
    amount, // Amount in lowest denomination from props
    currency, // Currency from props.
    name: orderDetail.user.name, // Title for your organization to display in checkout modal
    // image, // custom logo  url
    order_id: orderId, // order id from props
    // This handler menthod is always executed in case of succeeded payment

    prefill: {
      name: orderDetail.user.name,
      email: orderDetail.user.email,
      contact: orderDetail.user.mobile,
    },
    handler: (response) => {
      // console.log("succeeded",response);
       // paymentId.current = response.razorpay_payment_id;
      const sig = crypto
        .createHmac("sha256", keySecret)
        .update(`${orderId}|${response.razorpay_payment_id}`)
        .digest("hex")
        .toString();
      // // Most important step to capture and authorize the payment. This can be done of Backend server.
      const succeeded = sig === response.razorpay_signature;

      // // If successfully authorized. Then we can consider the payment as successful.
      if (succeeded) {
        handlePayment("succeeded", {
          orderId,
          paymentId: response.razorpay_payment_id,
          signature: response.razorpay_signature,
        });
      } else {
        handlePayment("failed", {
          orderId,
          paymentId: response.razorpay_payment_id,
        });
      }
    },
    modal: {
      confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
      // This function is executed when checkout modal is closed
      // There can be 3 reasons when this modal is closed.
      ondismiss: async (reason) => {
        const {
          reason: paymentReason,
          field,
          step,
          code,
        } = reason && reason.error ? reason.error : {};
        // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly.
        if (reason === undefined) {
          // console.log("cancelled");
          handlePayment("Cancelled");
        }
        // Reason 2 - When modal is auto closed because of time out
        else if (reason === "timeout") {
          // console.log("timedout");
          handlePayment("timedout");
        }
        // Reason 3 - When payment gets failed.
        else {
          console.log("failed");
          handlePayment("failed", {
            paymentReason,
            field,
            step,
            code,
          });
        }
      },
    },
    // This property allows to enble/disable retries.
    // This is enabled true by default.
    retry: {
      enabled: false,
    },
    timeout: 900, // Time limit in Seconds
    theme: {
      color: "", // Custom color for your checkout modal.
    },
  };

  useEffect(() => {
    displayRazorpay(options);
  }, [options]);

  return null;
};

export default RenderRazorpay;
