import React from "react";
import Header from "./../components/Header";
import ShopSection from "./../components/homeComponents/ShopSection";
import ContactInfo from "./../components/homeComponents/ContactInfo";
import CalltoActionSection from "./../components/homeComponents/CalltoActionSection";
import Footer from "./../components/Footer";
import CategorySection from "../components/homeComponents/CategorySection";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import BestDealSection from "../components/homeComponents/BestDealSection";
import CustomThumbSection from "../components/homeComponents/CustomThumbSection";
import MobileNav from "../components/MobileNav";
import MetaTags from "react-meta-tags";
import PopupModal from "../components/homeComponents/PopupModal";
const HomeScreen = ({ match }) => {
  // window.scrollTo(0, 0);
  const keyword = match.params.keyword;
  const pageNumber = match.params.page_number || 1;
  const pageSize = match.params.page_size || 18;
  return (
    <div>
      <MetaTags>
        <title>Home-Agrocroft</title>
        <meta
          name="description"
          content="Agrocroft - Your go-to online store for same-day delivery of quality agricultural products. Shop fresh produce, organic items, and more, delivered to your doorstep within hours. Experience fast, reliable, and convenient shopping with Agrocroft."
        />
        <meta property="og:title" content="Home-Agrocroft" />
        <meta property="og:image" content="./../public/favicon.png" />
      </MetaTags>
      <PopupModal show={true}></PopupModal>
      <Header />
      <MobileNav />
      <div className="row banner-row">
        <Carousel
          autoPlay="true"
          infiniteLoop="true"
          showIndicators="false"
          showThumbs={false}
        >
          {/* {products.map((product) => ( */}
          <div>
            <img
              className="banner-image"
              src="/images/agro_banner1.jpg"
              alt="no_banner.png"
            />
            {/* <p className="legend">Legend 1</p> */}
          </div>

          <div>
            <img
              className="banner-image"
              src="/images/agro_banner2.jpg"
              alt="no_banner.png"
            />
            {/* <p className="legend">Legend 1</p> */}
          </div>
          {/* ))} */}
        </Carousel>
      </div>
      {/* If user Search any keyword */}
      {keyword && (
        <>
          <ShopSection keyword={keyword} pageNumber={pageNumber} pageSize={12} />
        </>
      )}

      <BestDealSection />
      <CategorySection />
      <CustomThumbSection groupId={"66644c6e84ea229fc8ee4ecf"} />
      {!keyword && (
        <>
          <ShopSection keyword={keyword} pageNumber={pageNumber} pageSize={pageSize} />
        </>
      )}

      <CalltoActionSection />
      <ContactInfo />
      <Footer />
    </div>
  );
};

export default HomeScreen;
