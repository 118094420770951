import React, {useState } from "react";
import { POSTAL_CODES } from "../../Redux/Constants/GlobalConstant";
import Select from "react-select";

const PopupModal = ({ show }) => {
  const options = POSTAL_CODES;
  const curPin = localStorage.getItem("curPin");
  const [showModel, setShowModel] = useState(show);
  const showHideClassName = (showModel && !curPin)
    ? "modal display-block"
    : "modal display-none";

  const handleClose = () => {
    if (selectedOption.value != null) {
      localStorage.setItem("curPin", JSON.stringify(selectedOption));
      setShowModel(false);
    }
  };

  const [selectedOption, setSelectedOption] = useState({
    label: "Enter / Select Pincode",
    value: null,
  });

  const handleChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedOption(selectedOption);
    // setPostalCode(selectedOption.label);
    // console.log(postalCode);
    // this.setState({ selectedOption }, () =>
    //   console.log(`Option selected:`, this.state.selectedOption)
    // );
  };

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="Login">
            Pincode
          <Select
            className="shipping-pincode-select"
            value={selectedOption}
            onChange={(e) => handleChange(e)}
            options={options}
            defaultValue={""}
            required
          />
          <button type="button" onClick={handleClose}>
            Done
          </button>
        </div>
      </section>
    </div>
  );
};

export default PopupModal;
