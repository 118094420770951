 import {
  BEST_DEAL_PRODUCT_LIST_FAIL,
  BEST_DEAL_PRODUCT_LIST_REQUEST,
  BEST_DEAL_PRODUCT_LIST_SUCCESS,
  CAT_LIST_FAIL,
  CAT_LIST_REQUEST,
  CAT_LIST_SUCCESS,
  CAT_PRODUCT_LIST,
  GROUP_CATEGORY_FAIL,
  GROUP_CATEGORY_REQUEST,
  GROUP_CATEGORY_SUCCESS,
   GROUP_LIST_FAIL,
  GROUP_LIST_REQUEST,
  GROUP_LIST_SUCCESS,
  GROUP_PRODUCT_FAIL,
  GROUP_PRODUCT_REQUEST,
  GROUP_PRODUCT_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
} from "../Constants/ProductConstants";

// PRODUCT LIST
export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        pages: action.payload.pages,
        page: action.payload.page,
        products: action.payload.products,
      };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};



// SINGLE PRODUCT
export const productDetailsReducer = (
  state = { product: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// PRODUCT REVIEW CREATE
export const productCreateReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REVIEW_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};

// GROUP LIST
export const groupListReducer = (state = { groups: [] }, action) => {
  switch (action.type) {
    case GROUP_LIST_REQUEST:
      return { loading: true, groups: [] };
      
    case GROUP_LIST_SUCCESS:
      return {
        loading: false,
        groups: action.payload.groups,
      };
      // case GROUP_PRODUCT_LIST:
      // return {
      //   loading: false,
      //   groupProducts: action.payload,
      // };
    case GROUP_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Category List Based on Group
export const groupCategoryListReducer = (state = { groups: [] }, action) => {
  switch (action.type) {
    case GROUP_CATEGORY_REQUEST:
      return { loading: true, groupCategories: [] };

    case GROUP_CATEGORY_SUCCESS:
      return {
        loading: false,
        groupCategories: action.payload,
      };
    case GROUP_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Product List Based on Group
export const groupProductListReducer = (state = { groups: [] }, action) => {
  switch (action.type) {
    case GROUP_PRODUCT_REQUEST:
      return { loading: true, groupProducts: [] };

    case GROUP_PRODUCT_SUCCESS:
      return {
        loading: false,
        groupProducts: action.payload,
      };
    case GROUP_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//Category List
export const categoryReducer = (state = { groups: [] }, action) => {
  switch (action.type) {
    case CAT_LIST_REQUEST:
      return { loading: true, groups: [] };
    case CAT_LIST_SUCCESS:
      return {
        loading: false,
        pages: action.payload.pages,
        page: action.payload.page,
        groups: action.payload.groups,
      };
    case CAT_PRODUCT_LIST:
      return {
        loading: false,
        category: action.payload,
      };
    case CAT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
  
};

// PRODUCT LIST
export const bestDealProductReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case BEST_DEAL_PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case BEST_DEAL_PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case BEST_DEAL_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
