import React from "react";
import "./App.css";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import SingleProduct from "./screens/SingleProduct";
import Login from "./screens/Login";
import Register from "./screens/Register";
import CartScreen from "./screens/CartScreen";
import ShippingScreen from "./screens/ShippingScreen";
import ProfileScreen from "./screens/ProfileScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
// import NotFound from "./screens/NotFound";
import PrivateRouter from "./PrivateRouter";
import SingleGroup from "./screens/SingleGroup";
import LoginOtp from "./screens/LoginOtp";
import PolicyScreen from "./screens/PolicyScreen";
import TermConditionScreen from "./screens/TermConditionScreen";
import DisclaimerScreen from "./screens/DisclaimerScreen";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" component={HomeScreen} exact />
        <Route path="/search/:keyword" component={HomeScreen} exact />
        <Route path="/page/:pagenumber" component={HomeScreen} exact />
        <Route
          path="/search/:keyword/page/:pageNumber"
          component={HomeScreen}
          exact
        />
        <Route path="/group/:id" component={SingleGroup} />
        <Route path="/products/:id" component={SingleProduct} />
        <Route path="/login" component={Login} />
        <Route path="/login-otp" component={LoginOtp} />
        <Route path="/register/:refId" component={Register} />
        <Route path="/register" component={Register} />
        <PrivateRouter path="/profile" component={ProfileScreen} />
        <Route path="/cart/:id?" component={CartScreen} />
        <PrivateRouter path="/shipping" component={ShippingScreen} />
        <PrivateRouter path="/payment" component={PaymentScreen} />
        <PrivateRouter path="/placeorder" component={PlaceOrderScreen} />
        <PrivateRouter path="/order/:id" component={OrderScreen} />
        <Route path="/policy" component={PolicyScreen} />
        <Route path="/disclaimer" component={DisclaimerScreen} />
        <Route path="/term-condition" component={TermConditionScreen} />
        {/* <Route path="*" component={NotFound} /> */}
      </Switch>
    </Router>
  );
};

export default App;
