import React from "react";
import Header from "../components/Header";
import MobileNav from "../components/MobileNav";

const PolicyScreen = () => {
  return (
    <>
      <Header />
      <MobileNav />
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        <h1>Privacy Policy</h1>
        <p>Last updated on June 10, 2025</p>
        <p>
          We, Agrocroft (formerly GC Groups) ("Propwriter"), are dedicated to
          safeguarding your personal information and ensuring your privacy and
          security. Your trust is of utmost importance to us.
        </p>
        <p>
          This Privacy Policy details how we collect, use, process, and disclose
          information about you. By using our website/app/platform and related
          services, you agree to the terms of this Privacy Policy in addition to
          our 'Terms of Use.' We encourage you to review this policy regularly
          to stay informed about how we handle your information and any updates
          to these practices.
        </p>
        <p>
          This Privacy Policy covers our privacy practices for all websites,
          products, and services linked to it. However, it does not apply to
          affiliates and partners with their own privacy policies. We recommend
          reviewing the privacy policy of the applicable site in such cases.
        </p>
        <p>
          If you have any questions regarding this Privacy Policy, please
          contact us at{" "}
          <a href="mailto:privacy@agrocroft.com">privacy@agrocroft.com</a>.
        </p>

        <h2>The Collection, Storage, and Use of Information</h2>
        <p>
          We may automatically store certain information such as your web
          browser, IP address, cookies on your device, and other information
          based on your behavior on the website. This helps us conduct internal
          research on user demographics, interests, and behavior to better
          understand and serve our users. This data is compiled and analyzed in
          an aggregated manner.
        </p>
        <p>
          We may share personal information we collect with contractors,
          advertisers, and service providers who are bound by confidentiality
          agreements and are only permitted to use the information for specified
          purposes.
        </p>
        <p>
          We may also share your Mobile IP/Device IP with third parties, who, to
          the best of our knowledge and based on their representations, do not
          store this information.
        </p>
        <p>
          We collect and store personal information you provide on our
          website/app to ensure a seamless, efficient, and safe experience,
          customized to your needs. This includes:
        </p>
        <ul>
          <li>Enabling the provision of services you opt for</li>
          <li>
            Communicating necessary account and product/service-related
            information
          </li>
          <li>Providing quality customer care services</li>
          <li>Conducting fraud and money laundering prevention checks</li>
          <li>Complying with applicable laws, rules, and regulations</li>
          <li>
            Providing information and offers on products and services, updates,
            promotions, and related services
          </li>
        </ul>
        <p>
          We may share information with third parties to carry out your service
          requests when necessary. We may also use your contact information to
          send notifications and promotional materials based on your interests
          and activities.
        </p>

        <h2>Permissions and Data Usage</h2>
        <p>
          If you allow us to access your contact list, we can provide social
          features such as sharing orders, inviting contacts, and sending
          referral links. This information is synced from your phone and stored
          on our servers.
        </p>
        <p>
          You may also provide payment-related financial information, which we
          are committed to keeping secure. We only use this information to
          complete transactions with you. Additionally, your information may be
          used for communication, screening for fraud, and optimizing site
          performance.
        </p>
        <p>
          We may require permissions to access your device information,
          location, SMS, phone, email, photos/media/files, Wi-Fi connection, and
          camera to enhance your experience and provide tailored services.
        </p>
        <p>
          You can choose not to provide specific information or use certain
          services/features on our website/app. However, transacting over the
          internet involves inherent risks. We encourage you to follow security
          practices such as not sharing login information and reporting
          suspicious activities.
        </p>
        <p>
          We use "cookies" to analyze web page flow, measure promotional
          effectiveness, and enhance trust and safety. Cookies help provide
          services and may include an anonymous unique identifier. You can
          decline cookies if your browser allows, though this may limit certain
          features on the website.
        </p>
        <p>
          We may retain your information for as long as necessary or as required
          by law. We may disclose information to comply with legal requirements,
          detect and prevent fraud, and protect the rights and safety of the
          Company and others.
        </p>
        <p>
          The website may contain links to other websites. We are not
          responsible for the privacy practices of these websites.
        </p>

        <h2>Choices Regarding Information Collection, Use, and Distribution</h2>
        <p>
          We have implemented appropriate physical, electronic, and managerial
          procedures to protect your information. However, transmissions over
          the Internet cannot be made completely secure. By using the website,
          you agree that the Company will not be liable for unauthorized acts of
          third parties.
        </p>
        <p>
          We may share information with governmental agencies or third parties
          under legal obligations, for fraud detection, to respond to
          intellectual property claims, or to protect the rights and safety of
          the Company and others.
        </p>
        <p>
          We will not ask for sensitive data via email or phone. If you receive
          such requests, please report them to{" "}
          <a href="mailto:privacy@agrocroft.com">privacy@agrocroft.com</a>.
        </p>

        <h2>Changes to the Privacy Policy</h2>
        <p>
          Agrocroft reserves the right to change this policy at any time.
          Changes will be effective immediately upon posting the revised Privacy
          Policy. We encourage you to review this page periodically for the
          latest updates.
        </p>

        <h2>Privacy Questions and Access to Your Information</h2>
        <p>
          If you have questions or suggestions regarding our privacy policy,
          contact us through our "Contact Us" page or at{" "}
          <a href="mailto:privacy@agrocroft.com">privacy@agrocroft.com</a>. You
          may also view or edit your personal information online. To obtain or
          correct information, contact us with verification details. We will
          respond within 30 days.
        </p>

        <h2>Grievance Redressal Officer</h2>
        <p>
          If you need to correct or update any information, you can do so online
          or by contacting us at{" "}
          <a href="mailto:complain@agrocroft.com">complain@agrocroft.com</a>. If
          you lose access to the website, contact us at the same email address.
        </p>

        <h2>About Agrocroft</h2>
        <h3>A. Instant Delivery Service in India</h3>
        <p>
          Shop on the go and get anything delivered in minutes. From groceries
          to fresh fruits and vegetables, cakes and bakery items, meats and
          seafood, cosmetics, electronics, baby care products, and more. We
          deliver it to your doorstep quickly and safely.
        </p>

        <h3>B. Single App for All Your Daily Needs</h3>
        <p>
          Order thousands of products with just a tap - milk, eggs, bread,
          cooking oil, rice, fresh fruits and vegetables, spices, chocolates,
          chips, biscuits, cold drinks, shampoos, soaps, pet food, electronics,
          organic and gourmet products from neighborhood stores, and much more.
        </p>

        <h3>C. Order Online on Agrocroft for Instant Delivery</h3>
        <p>
          Enjoy the magic of instant delivery. We currently serve cities like
          Gorakhpur, Deoria, Maharajganj, and Nichlaul.
        </p>
      </div>
    </>
  );
};

export default PolicyScreen;
