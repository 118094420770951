import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Message from "../components/LoadingError/Error";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryProductList,
  listCategroyGroupWise,
  listProductsGroupWise,
  // listProductDetails,
} from "../Redux/Actions/ProductActions";
import Loading from "../components/LoadingError/Loading";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import QuantityInput from "../components/QuantityInput";
import MobileNav from "../components/MobileNav";
import {
  IMAGE_CACHE_URL,
} from "../Redux/Constants/GlobalConstant";
// import { IMAGE_BASE_URL } from "../Redux/Constants/GlobalConstant";

const SingleGroup = ({ history, match }) => {
  window.scrollTo(0, 0);

  const GroupId = match.params.id;
  const dispatch = useDispatch();
  const [productData, setProductData] = useState([]);
  const [selectedCat, setSelectedCat] = useState(null);
  const [selectedCatName, setSelectedCatName] = useState("");

  const groupProductList = useSelector((state) => state.groupProductList);
  const { loading, error, groupProducts } = groupProductList;

  const groupCategoryList = useSelector((state) => state.groupCategoryList);
  const { groupCategories } = groupCategoryList;

  const catProductList = useSelector((state) => state.categoryData);
  const { category } = catProductList;

  useEffect(() => {
    dispatch(listCategroyGroupWise(GroupId));
    dispatch(listProductsGroupWise(GroupId));
  }, [dispatch, GroupId]);

  useEffect(() => {
    if (groupProducts) {
      // console.log("Group Wise Products", groupProducts);
      setProductData(groupProducts.products);
      setSelectedCatName(groupProducts?.group_name);
    }
  }, [groupProducts]);

  useEffect(() => {
    if (category) {
      setProductData(category?.products);
      // console.log(category?.products);
    }
  }, [category]);

  const handleCategoryClick = (e, cat) => {
    setSelectedCat(cat._id);
    setSelectedCatName(cat.category_name);
    // console.log(cat, selectedCat);
    // console.log("Going to dispatch Cat Product List", cat);
    dispatch(categoryProductList(cat._id));
  };

  const handleAllClick = (e, cat) => {
    dispatch(listProductsGroupWise(GroupId));
  };

  return (
    <>
      <Header />
      <MobileNav />
      <div className="container single-product">
        {loading ? (
          <Loading />
        ) : error ? (
          <Message variant="alert-danger">{error}</Message>
        ) : (
          <div className="row group-row">
            <div className="col-md-3 row-col-3 left-category-row">
              <div className="left-category-scrollbar">
                <ul>
                  <li>
                    <Link
                      to="#"
                      className={"selected-cat-background"}
                      onClick={(e) => handleAllClick(e, GroupId)}
                    >
                      <div className="left-category-item-wrap">
                        <div className="category-img">
                          <img
                            src={IMAGE_CACHE_URL + "favicon.png"}
                            // src="https://agrocroft-ecom-bucket.s3.ap-south-1.amazonaws.com/prod/favicon.png"

                            alt={"all.jpg"}
                          />
                        </div>
                        <div className="category-name">{"All Items"}</div>
                      </div>
                    </Link>
                  </li>
                  {groupCategories?.categories?.map((category, i) => (
                    <li key={i}>
                      <Link
                        to="#"
                        className={
                          category._id === selectedCat
                            ? "selected-cat-background"
                            : ""
                        }
                        onClick={(e) =>
                          handleCategoryClick(e.target.value, category)
                        }
                      >
                        <div className="left-category-item-wrap">
                          <div className="category-img">
                            <img
                              src={IMAGE_CACHE_URL + "favicon.png"}
                              // src="https://agrocroft-ecom-bucket.s3.ap-south-1.amazonaws.com/prod/favicon.png"
                              // src={
                              //   IMAGE_BASE_URL +"/"+
                              //   category.group +
                              //   "/" +
                              //   category.category_img
                              // }
                              alt={category?.category_name}
                            />
                          </div>
                          <div className="category-name">
                            {category?.category_name}
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-8 row-col-8 right-category-row">
              <h3>{selectedCatName ? selectedCatName : ""}</h3>
              <div className="group-shopcontainer row ">
                {loading ? (
                  <div className="mb-5">
                    <Loading />
                  </div>
                ) : error ? (
                  <Message variant="alert-danger">{error}</Message>
                ) : (
                  <>
                    {productData?.map((product) => (
                      <div
                        className="shop col-lg-3 col-md-4 col-sm-4"
                        key={product._id}
                      >
                        <div className="border-product">
                          <Link to={`/products/${product._id}`}>
                            <div className="shopBack">
                              {product.mrp_price - product.price > 0 && (
                                <div>
                                  <div className="offer_strip">
                                    {Math.floor(
                                      ((product.mrp_price - product.price) /
                                        product.price) *
                                        100
                                    )}{" "}
                                    %
                                  </div>
                                </div>
                              )}
                              <img
                                src={IMAGE_CACHE_URL + product.image}
                                alt={product.name}
                              />
                            </div>
                          </Link>

                          <div className="shoptext">
                            <p>
                              <Link to={`/products/${product._id}`}>
                                {product.name}
                              </Link>
                            </p>

                            {/* <Rating
                                value={product.rating}
                                text={`${product.numReviews} reviews`}
                              /> */}
                            <div className="price-button-thumb-sec">
                              <h3>
                                ₹ {product.price}
                                {product.mrp_price - product.price > 0 && (
                                  <span className="text-overline">
                                    ₹ {product.mrp_price}
                                  </span>
                                )}
                                {product.mrp_price - product.price < 0 && (
                                  <span className="text-overline"> </span>
                                )}
                              </h3>
                              <QuantityInput
                                productId={product._id}
                                renderAddButton={true}
                                qty={0}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SingleGroup;
