import React from "react";
import { Link } from "react-router-dom";

const MobileNav = () => {
  return (
    <div className="bottom-navbar-section">
      <div className="bottom-navbar">
        <Link to="/">Home</Link>
        <Link to="/">Best Deal</Link>
        <Link to="/profile">Order</Link>
        <Link to="/cart">Cart</Link>
      </div>
    </div>
  );
};

export default MobileNav;
