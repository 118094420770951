import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import { OrderCancellationRequest } from "../../Redux/Actions/OrderActions";
import Toast from "../LoadingError/Toast";
import { toast } from "react-toastify";
 
const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 1000,
};

const Orders = (props) => {
  const { loading, error, orders } = props;
  const [selectedOption, setSelectedOption] = useState(0);

  const dispatch = useDispatch();

  const handleChange = (e, orderId) => {
    if (e.target.value === 2) {
      if (window.confirm("Are you sure want to Cancel?")) {
        dispatch(OrderCancellationRequest(orderId));
        toast.success("Order Cancel Reqeuest", ToastObjects);

      } else {
        setSelectedOption(0);
      }
    }
  };
  return (
    <div className=" d-flex justify-content-center align-items-center flex-column">
      <Toast/>
      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant="alert-danger">{error}</Message>
      ) : (
        <>
          {orders.length === 0 ? (
            <div className="col-12 alert alert-info text-center mt-3">
              No Orders
              <Link
                className="btn btn-success mx-2 px-3 py-2"
                to="/"
                style={{
                  fontSize: "12px",
                }}
              >
                START SHOPPING
              </Link>
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>PAYMENT</th>
                    <th>DATE</th>
                    <th>TOTAL</th>
                    <th>STATUS</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr
                      className={`${
                        order.isPaid || order.isCOD
                          ? "alert-success"
                          : "alert-danger"
                      }`}
                      key={order._id}
                    >
                      <td>
                        <a href={`/order/${order._id}`} className="link">
                          {order._id}
                        </a>
                      </td>
                      <td>
                        {order.isPaid ? (
                          <span>Paid - {order.isDelivered && "Delivered"}</span>
                        ) : (
                          <span>Not Paid</span>
                        )}
                      </td>
                      <td>
                        {order.isPaid && moment(order.paidAt).calendar()}
                        {!order.isPaid && moment(order.createdAt).calendar()}
                      </td>
                      <td>₹ {order.totalPrice}</td>
                      <td>
                        {order.isCOD && "COD"}
                        {order.isDelivered && "Delivered"}
                        {order.isCanceled && "Canceled"}
                      </td>
                      <td>
                        {order.isCanceled && order.isPaid && !order.isDelivered && "Refund Requested"}
                        {!order.isCanceled && !order.isDelivered && (
                          <select
                            className="form-select"
                            onChange={(e) => handleChange(e, order._id)}
                            defaultValue={selectedOption}
                          >
                            <option value={0}>Select</option>
                            {/* <option value={1}>Order Info</option> */}
                            {!order.isCanceled && !order.isDelivered && (
                              <option value={2}>Cancel Order</option>
                            )}
                          </select>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Orders;
