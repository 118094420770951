import React from "react";

const ShareButton = ({ url, message, text }) => {
  const handleShare = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      message
    )}%20${encodeURIComponent(url)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <button
      onClick={handleShare}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        border: "none",
        background: "none",
      }}
    >
      <i className="fas fa-user"></i> - <span>{text}</span>
    </button>
  );
};

export default ShareButton;
