import React from "react";
import Header from "../components/Header";
import MobileNav from "../components/MobileNav";

const DisclaimerScreen = () => {
  return (
    <>
      <Header />
      <MobileNav />
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        <h1>Disclaimer</h1>
        <p>
          The information provided by Agrocroft.com ("we," "us" or "our") on our
          website and our mobile application is for general informational
          purposes only. All information on the website and our mobile
          application is provided in good faith, however, we make no
          representation or warranty of any kind, express or implied, regarding
          the accuracy, adequacy, validity, reliability, availability, or
          completeness of any information on the website or our mobile
          application.
        </p>
        <p>
          Under no circumstance shall we have any liability to you for any loss
          or damage of any kind incurred as a result of the use of the site or
          reliance on any information provided on the site. Your use of the site
          and your reliance on any information on the site is solely at your own
          risk.
        </p>

        <h2>External Links Disclaimer</h2>
        <p>
          The site may contain (or you may be sent through the site) links to
          other websites or content belonging to or originating from third
          parties or links to websites and features in banners or other
          advertising. Such external links are not investigated, monitored, or
          checked for accuracy, adequacy, validity, reliability, availability,
          or completeness by us.
        </p>
        <p>
          We do not warrant, endorse, guarantee, or assume responsibility for
          the accuracy or reliability of any information offered by third-party
          websites linked through the site or any website or feature linked in
          any banner or other advertising. We will not be a party to or in any
          way be responsible for monitoring any transaction between you and
          third-party providers of products or services.
        </p>

        <h2>Professional Disclaimer</h2>
        <p>
          The site cannot and does not contain professional advice. The grocery
          and related information is provided for general informational and
          educational purposes only and is not a substitute for professional
          advice.
        </p>
        <p>
          Accordingly, before taking any actions based upon such information, we
          encourage you to consult with the appropriate professionals. We do not
          provide any kind of professional advice. The use or reliance on any
          information contained on this site is solely at your own risk.
        </p>

        <h2>Product Descriptions and Prices</h2>
        <p>
          We strive to ensure that the product descriptions, prices, and
          availability on our website are accurate. However, we do not warrant
          that the descriptions, prices, or other content available on the site
          are accurate, complete, reliable, current, or error-free. We reserve
          the right to correct any errors, inaccuracies, or omissions, and to
          change or update information at any time without prior notice.
        </p>

        <h2>Health and Allergy Information</h2>
        <p>
          The information on allergens and dietary restrictions provided on the
          site is for general informational purposes only and is not intended to
          substitute professional medical advice. Always read labels, warnings,
          and directions provided with the product before using or consuming it.
          For additional information about a product, please contact the
          manufacturer.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Disclaimer, you can contact us
          at:
        </p>
        <p>Agrocroft (GC Groups)</p>
        <p> Nichlaul Maharajganj</p>
        <p> agrocroft.info@gmail.com</p>
        <p> 9517290551</p>
      </div>
    </>
  );
};

export default DisclaimerScreen;
