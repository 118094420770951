import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import ProfileTabs from "../components/profileComponents/ProfileTabs";
import { getUserDetails } from "../Redux/Actions/userActions";
import Orders from "./../components/profileComponents/Orders";
import moment from "moment";
import { listMyOrders } from "../Redux/Actions/OrderActions";
import MobileNav from "../components/MobileNav";
import ShareButton from "../components/ShareButton";

const ProfileScreen = () => {
  window.scrollTo(0, 0);
  const [url, setUrl] = useState("https://agrocroft.com/register/");
  const whatsappMessage =
    `
🌾 *Agrocroft: Your Trusted Online Grocery Store!* 🛒

      Order fresh groceries delivered right to your doorstep with just a few clicks. 🍎🥦🥖

      ✨ *Why Agrocroft?* ✨
      ✅ Convenient Online Ordering
      ✅ Fresh and Quality Products
      ✅ Fast and Reliable Delivery

      📢 *Special Offer: Become a Member!* 📢
      Register now and become a member of Agrocroft to enjoy exclusive benefits:
      🎉 Win a fantastic trip every year!
      💵 Earn extra income through our referral program!

      *Don’t miss out! Register today and experience the convenience of Agrocroft.* 🌟

      Click the link to get started: ` +
    url +
    ` 🛒
`.trim();

  const whatsappMessageHindi =
    `
🌾 *(Agrocroft) - एग्रोक्रॉफ्ट: आपका विश्वसनीय ऑनलाइन ग्रॉसरी स्टोर!* 🛒

      ताज़ा ग्रॉसरीज़ को कुछ ही क्लिक में अपने दरवाजे तक पहुँचाएं। 🍎🥦🥖

      ✨ *क्यों चुनें एग्रोक्रॉफ्ट?* ✨
      ✅ सुविधाजनक ऑनलाइन ऑर्डरिंग
      ✅ ताज़ा और गुणवत्तापूर्ण उत्पाद
      ✅ तेज़ और विश्वसनीय डिलीवरी

      📢 *विशेष ऑफर: सदस्य बनें!* 📢
      अब पंजीकरण करें और एग्रोक्रॉफ्ट के सदस्य बनें और विशेष लाभ प्राप्त करें:
      🎉 हर साल शानदार यात्रा जीतें!
      💵 हमारे रेफरल प्रोग्राम के माध्यम से अतिरिक्त आय कमाएं!

      *मौका न चूकें! आज ही पंजीकरण करें और एग्रोक्रॉफ्ट की सुविधा का अनुभव करें।* 🌟

      शुरू करने के लिए लिंक पर क्लिक करें: ` +
    url +
    ` 🛒
`.trim();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading, error, orders } = orderListMy;

  useEffect(() => {
    dispatch(listMyOrders());
    dispatch(getUserDetails("profile"));
    if (userInfo) setUrl("https://agrocroft.com/register/" + userInfo._id);
  }, [dispatch, userInfo]);

  return (
    <>
      <Header />
      <MobileNav />
      <div className="container mt-lg-5 mt-3">
        <div className="row align-items-start">
          <div className="col-lg-3 p-0 shadow ">
            <div className="author-card pb-0 pb-md-3">
              <div className="author-card-cover"></div>
              <div className="author-card-profile row">
                <div className="author-card-avatar col-md-5">
                  <img src="./images/user.png" alt="userprofileimage" />
                </div>
                <div className="author-card-details col-md-7">
                  <h5 className="author-card-name mb-2">
                    <strong>{userInfo.name}</strong>
                  </h5>
                  <span className="author-card-position">
                    <>Joined {moment(userInfo.createdAt).format("LL")}</>
                  </span>
                </div>
              </div>
            </div>
            <div className="wizard pt-3 ">
              <div className="d-flex align-items-start">
                <div
                  className="nav align-items-start flex-column col-12 nav-pills me-3 "
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    className="nav-link"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    Profile Settings
                  </button>
                  <button
                    className="nav-link d-flex justify-content-between active"
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                  >
                    Orders List
                    <span className="badge2">{orders ? orders.length : 0}</span>
                  </button>
                  <ShareButton
                    url={"https://agrocroft.com/register/" + userInfo._id}
                    message={whatsappMessage}
                    text="Share on Whatsapp"
                  />
                   <ShareButton
                    url={"https://agrocroft.com/register/" + userInfo._id}
                    message={whatsappMessageHindi}
                    text="WhatsApp पर साझा करें"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* panels */}
          <div
            className="tab-content col-lg-9 pb-5 pt-lg-0 pt-3"
            id="v-pills-tabContent"
          >
            <div
              className="tab-pane fade"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <ProfileTabs />
            </div>
            <div
              className="tab-pane fade show active"
              id="v-pills-profile"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
            >
              <Orders orders={orders} loading={loading} error={error} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileScreen;
