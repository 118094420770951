import {
  OTP_REQUEST,
  OTP_REQUEST_FAIL,
  OTP_REQUEST_SUCCESS,
  OTP_VERIFIED_FAIL,
  OTP_VERIFIED_REQUEST,
  OTP_VERIFIED_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_WALLET,
} from "../Constants/UserContants";
import { BASE_URL } from "../Constants/GlobalConstant";

import axios from "axios";
import { ORDER_LIST_MY_RESET } from "../Constants/OrderConstants";

// LOGIN
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      BASE_URL + `/api/users/login`,
      { email, password },
      config
    );
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// LOGOUT
export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: ORDER_LIST_MY_RESET });
};

// Sending OTP to Mobile Number
export const sendOTP = (mobile, email) => async (dispatch) => {
  try {
    dispatch({ type: OTP_REQUEST, payload: null });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      BASE_URL + `/api/users/send-otp`,
      { mobile, email },
      config
    );
    dispatch({ type: OTP_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: OTP_REQUEST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Sending OTP to Mobile Number For LoginOTP Screen
export const sendOTPLogin = (mobile) => async (dispatch) => {
  try {
    dispatch({ type: OTP_REQUEST, payload: null });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      BASE_URL + `/api/users/send-otp-login`,
      { mobile },
      config
    );
    dispatch({ type: OTP_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: OTP_REQUEST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
    localStorage.setItem("user_type","new");

  }
};

// Verify OTP
export const verifyOTP = (key, otp) => async (dispatch) => {
  try {
    dispatch({ type: OTP_VERIFIED_REQUEST, payload: null });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      BASE_URL + `/api/users/verify-otp`,
      { key, otp },
      config
    );
    dispatch({ type: OTP_VERIFIED_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: OTP_VERIFIED_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// Verify OTP for Login Screen
export const verifyOTPLogin = (key, otp) => async (dispatch) => {
  try {
    dispatch({ type: OTP_VERIFIED_REQUEST, payload: null });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      BASE_URL + `/api/users/verify-otp-login`,
      { key, otp },
      config
    );
    dispatch({ type: OTP_VERIFIED_SUCCESS, payload: data });
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));

  } catch (error) {
    alert("hi there");
    dispatch({
      type: OTP_VERIFIED_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

// REGISTER
export const register = (name, mobile, email, password, ref_id) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      BASE_URL + `/api/users`,
      { name, mobile, email, password, ref_id },
      config
    );
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// USER DETAILS
export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(BASE_URL + `/api/users/${id}`, config);
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    });
  }
};

// UPDATE PROFILE
export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_PROFILE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      BASE_URL + `/api/users/profile`,
      user,
      config
    );
    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    });
  }
};

// USER DETAILS
export const getUserWalletDetail = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_WALLET });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(BASE_URL + `/api/users/profile/cash-point/${id}`, config);
    dispatch({ type: USER_WALLET, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_WALLET,
      payload: message,
    });
  }
};
